//---------MAIN---------------------
.main__title {
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: #ffffff;
}

//----------------------------------

.search__input {
  position: relative;
  display: block;
  color: #ffffff;
  text-align: center;
}

.search__list {
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 210px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 2px 2px 6px 6px;
}

.search__list-item {
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  &:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #3c66c4;

    &:last-child {
      border-radius: 2px 2px 6px 6px;
    }
  }
}

//----------------------------------

.list {
  display: flex;
  justify-content: space-between;
}

.test-list {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  flex-direction: column;
  width: 95vw;
}


.list__obj-item {
  overflow-y: scroll;
  max-height: 500px;
}

.list__item {
  position: relative;

  padding-top: 15px;
  padding-left: 15px;

  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.list__item-title,
.list__item-text {
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  position: relative;

  margin: 0;
  margin-bottom: 30px;
  margin-left: 100px;
  margin-right: 40px;

  outline: none;
  word-wrap: break-word;

  &::after {
    content: "Имя магазина";
    position: absolute;
    top: 50%;
    left: -80px;
    transform: translate(-10px, -50%);

    font-size: 9px;
    text-transform: uppercase;
    color: #3c66c4;
  }
}

.list__item-text {
  padding: 5px 0;

  word-wrap: break-word;

  &::after {
    content: "Хардкод имена";
  }
}

[contenteditable="true"] {
  box-shadow: 0 0 20px #ffffff;
  border-radius: 3px;
  background: rgba(63, 81, 181, 0.3);
  ;
}

.icons__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.icon {
  min-width: 30px;
  min-height: 35px;

  z-index: 2;

  &:hover {
    cursor: pointer;
    filter: saturate(300%);
  }
}

.title {
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-height: 70px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: #3c66c4;

  &:hover {
    cursor: pointer;
    filter: saturate(200%);
  }

  &:active {
    transform: scale(1.01);
  }
}

.list__add-button {
  margin: auto;

  &:hover {
    opacity: 0.8;
  }
}

//-------------------ADD FORM------------------------------------

.add-form__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 600px;
  min-height: 500px;
  padding: 60px 30px;

  border: 1px solid #4c4c4c4c;
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 20;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.5)
  }
}

.add-form__title {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  position: absolute;
  top: -20px;
  width: 90%;
  height: 50px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 152, 255, 0.4);
}

.add-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.add-form__input-wrapper {
  min-width: 450px;
}

.add-form__textarea {
  min-height: 100px;
  resize: none;
}

.add-form__button-wrapper {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 10px;
}

.add-form__close {
  position: absolute;
  top: -30px;
  right: -30px;
  outline: none;
}


.not-valid {
  background-color: yellowgreen;
}

.feedback-error .add-form__textarea {
  border-color: red;
}

.feedback-error .add-form__input {
  border-color: red;
}

.list-item--active .title {
  border-color: #A5260A;
  filter: grayscale(0.5);
}

//-------------------------------------------------------------------

@media (max-width: 600px) {
  .list {
    flex-direction: column;
    align-items: center;
  }
}