html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-image: url("assets/img/bg-net.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: 50%;

  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(2, 12, 41, 0.6);
    z-index: -1;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.title,
.text {
  margin: 0;
}

//------------ScrollBar------------------

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 50px rgba(45, 125, 200, 0.7);
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 50px rgba(45, 125, 200, 0.7);
}

//--------------------------------------

